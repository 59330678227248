import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactPage = () => (
  <Layout>
    <SEO title="Contact" />
    <div className={"container"}>
      <div className={"content"}>
        <div className={"title"}>
          <h1>Contact</h1>
        </div>

        <div className={"row"}>
          <div className={"col-7"}>
            <p>
              We take our commitment to our users seriously.
              <br />
              <br />
              If you have questions about how to use our software, have any
              feedback, or are experiencing technical difficulties, please do
              not hesitate to contact us.
            </p>
          </div>

          <div className={"col-5"}>
            <div className={"contact-items"}>
              <p>Email us at:</p>
              <h2>
                <a href="mailto:contact@kapsel.co">contact@kapsel.co</a>
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default ContactPage
